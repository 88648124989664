import React from "react"
import { graphql } from "gatsby"

import Seo from "components/Seo"
import Magazine from "components/Magazine"
import { Banner } from "components/Banner"
import Popup from "components/Popup"

export default function TemplateMagazine({ data, pageContext }) {
  const {
    dataMagazine,
    dataMagazineArticles,
    globals,
    allArticles,
  } = data?.craft
  const {
    title,
    seoTitle,
    seoDescription,
    seoImage,
  } = data?.craft?.dataMagazineArticles?.edges?.[0]?.dataMagazineCategory

  const [curpage] = pageContext.alternateEntries
  const articles = dataMagazineArticles?.edges?.[0]?.relatedEntries?.entries
  return (
    <>
      <Seo
        title={seoTitle || title}
        description={seoDescription}
        image={seoImage}
        defaults={data?.craft?.globals?.seo}
        pageContext={pageContext}
      />
      <Magazine
        allArticles={allArticles}
        dataCta={globals.dataCta}
        dataMagazine={dataMagazine}
        dataMagazineArticles={articles}
      />

      <Banner curPageTitle={curpage.title} pageContext={pageContext} />
      <Popup pageContext={pageContext} />
    </>
  )
}

export const query = graphql`
  query TemplateMagazineCategory($id: [Int]!, $siteId: Int!) {
    craft {
      # Globals
      globals(siteId: $siteId) {
        ...GlobalNavigationSocialQuery
        ...GlobalCtaSignUpQuery
        ...GlobalFooterSectionQuery
        ...GlobalCookieInfoQuery
        ...GlobalCtaReadMoreQuery
        ...GlobalSeoQuery
      }

      # NavigationHeader
      navigationHeaderItems: entries(
        section: navigationHeader
        siteId: $siteId
      ) {
        ...NavigationHeaderQuery
      }

      # NavigationFooter
      navigationFooterItems: entries(
        section: navigationFooter
        siteId: $siteId
      ) {
        ...NavigationFooterQuery
      }

      # Magazine
      dataMagazine: entries(section: magazine, siteId: $siteId) {
        __typename
        ... on Craft_Magazine {
          title
          fullUri
          magazineHeroBackground {
            id
            ...CraftImageFluid
          }
          magazineSlider {
            id
          }
          magazineHeroHeadlineVisual
          magazineHeroHeadline
          magazineHeroNavigation {
            id
            magazinHeroItemTitle
            magazineHeroItemLink {
              id
              uri
            }
          }
        }
      }

      # MagazineArticles
      dataMagazineArticles: categoriesConnection(id: $id, siteId: $siteId) {
        edges {
          dataMagazineCategory: node {
            title
            ... on Craft_MagazineCategoryCategory {
              seoTitle
              seoDescription
              seoKeywords
              seoImage {
                optimizedUrl: url(crop: { width: 1080, quality: 90 })
              }
            }
          }
          relatedEntries(siteId: $siteId) {
            entries {
              __typename
              id
              title
              fullUri
              postDate
              language
              ... on Craft_MagazineArticleMagazineEntry {
                magazineCat {
                  id
                  title
                  uri
                }
                magazineTeaserText
                magazineArticleContent {
                  __typename
                  ... on Craft_MagazineArticleContentMagazineArticleText {
                    articleText {
                      totalPages
                      content
                    }
                  }
                }
                magazineTeaserImage {
                  ...CraftImageFluid
                }
                articleHeroImage {
                  ...CraftImageFluid
                }
              }
            }
          }
        }
      }
      allArticles: entries(section: magazineArticle) {
        ... on Craft_MagazineArticleMagazineEntry {
          title
          fullUri
        }
      }
    }
  }
`
